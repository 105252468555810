<template>
  <card>
    <template v-slot:table>
      <v-form @submit.prevent="submitForm">
        <div class="form-register">
          <p>Adicionar PEV (Ponto de Entrega Voluntaria)</p>
          <v-row>
            <v-col cols="12">
              <div class="container-attachment">
                <p
                  @click="() => (addPevDialogActive = true)"
                  class="attachment-title"
                >
                  <v-icon class="attachment-icon"
                    >mdi-plus-circle-outline</v-icon
                  >Adicionar PEV
                </p>
              </div>
            </v-col>
          </v-row>

          <div class="container-list-attachments">
            <v-row
              v-for="(pev, i) in partnerForm.voluntaryDeliveryPoints"
              :key="i"
              class="container-list-attachment"
            >
              <v-col cols="2">
                <img
                  class="img-list-attachment"
                  :src="getImage(pev.photo.hash)"
                />
              </v-col>
              <v-col cols="9">
                <p class="address-list-attachment">{{ pev.name }}</p>
                <p class="zipcode-list-attachment">
                  {{ getAddress(pev.address) }}
                </p>
                <p class="zipcode-list-attachment">
                  {{ pev.address.zipCode | VMask(masks.zipCode) }}
                </p>
              </v-col>
              <v-col cols="1">
                <v-icon @click="delPev(pev)" class="delete-list-attachment-icon"
                  >mdi-close</v-icon
                >
              </v-col>
            </v-row>
          </div>
        </div>

        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn
            @click="$router.push({ name: 'pev' })"
            color="#ffffff"
            class="cancel-button black-3--text"
            >Sair</v-btn
          >
          <v-btn
            type="submit"
            :disabled="partnerForm.voluntaryDeliveryPoints.length <= 0"
            color="secondary-color-1"
            class="next-step-button white--text"
            >Confirmar e enviar</v-btn
          >
        </v-card-actions>
      </v-form>
      <AddPev
        v-if="addPevDialogActive"
        :active="addPevDialogActive"
        :partner="partnerForm"
        :functionButtonCancel="() => (addPevDialogActive = false)"
        :functionButtonAction="addPev"
      />
    </template>
  </card>
</template>

<script>
  import _ from "lodash";
  import { mapActions, mapGetters } from "vuex";
  import MasksMixin from "@/modules/core/mixins/masksMixin";

  import AddPev from "@/modules/partner/views/components/AddPev";
  import Card from "@/modules/core/views/templates/CardLayout";

  export default {
    name: "RegisterPEV",

    components: {
      Card,
      AddPev
    },

    mixins: [MasksMixin],

    data: () => ({
      addPevDialogActive: false
    }),

    computed: {
      ...mapGetters({
        selectedUserCRC: "user/getSelectedUserCRC",
        partnerForm: "partner/getPartnerForm"
      })
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("partner", ["createPartner", "setPartnerForm"]),

      getImage(hash) {
        return `${process.env.VUE_APP_API_BASE_URL}/file/${hash}`;
      },

      getAddress(address) {
        return `${address.address}, ${address.neighborhood} - ${address.city}/${address.state.uf}`;
      },

      delPev(pev) {
        this.partnerForm.voluntaryDeliveryPoints.splice(
          this.partnerForm.voluntaryDeliveryPoints.indexOf(pev),
          1
        );
      },

      addPev(newPev) {
        delete newPev.partner;
        this.partnerForm.voluntaryDeliveryPoints.push(newPev);
        this.addPevDialogActive = false;
      },

      async submitForm() {
        if (!this.selectedUserCRC) {
          this.setPartnerForm(this.partnerForm);
          this.$router.push({ name: "AddCRCPartner" });
          return;
        }

        this.partnerForm.contracts[0].crc.id = this.selectedUserCRC.id;

        try {
          const { data } = await this.createPartner(this.partnerForm);
          this.$router.push({ name: "pev" });
          this.toggleSnackbar({
            text: "Parceiro criado com sucesso!",
            type: "success"
          });
        } catch (err) {
          const message = _.get(err, "response.data.message");
          this.toggleSnackbar({
            text: message,
            type: "error"
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";
  @import "@/assets/stylesheets/forms";
  @import "@/assets/stylesheets/components";

  @include form-register();
  @include container-attachment();
  @include list-attachment();

  ::v-deep .row.container-list-attachment {
    margin: 10px 0 0 0;
  }
</style>
